import {useEffect} from 'react'
// Chakra imports
import {
    Box,
    Button,
    Flex,
    Grid,
    Icon,
    Image, Input, InputGroup,
    Portal,
    SimpleGrid,
    Spacer,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Text,
    useColorMode,
    useColorModeValue,
    Spinner
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconBox from "components/Icons/IconBox";
// Custom icons
import {
    WalletIcon,
} from "components/Icons/Icons.js";
import React, {useState} from "react";
// React Icons
import {BsArrowRight} from "react-icons/bs";
import {FiDollarSign} from "react-icons/fi";
import {BiNetworkChart} from "react-icons/bi";
import {AiOutlineBlock} from "react-icons/all";
import {BsFillPeopleFill} from "react-icons/all";
import {AiOutlineLineChart} from "react-icons/all";
import {GiProfit} from "react-icons/all";
import {AiFillPieChart} from "react-icons/all";
import {GrMoney} from "react-icons/all";
import {GiReceiveMoney} from "react-icons/all";
import {BsChevronDown} from "react-icons/bs";
// Navbar
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
// Web3
import {ethers} from 'ethers';
import {
    CONTRACT_ADDRESS,
    EASYBLOCK_ABI,
    PURCHASE_TOKEN_ABI,
    NFT_ADDRESS,
    NFT_ABI,
    REWARD_ADDRESS,
    REWARD_ABI
} from "../../contracts/EasyBlock";
// Toast
import toast, {Toaster, useToasterStore} from 'react-hot-toast';
// Analytics
import {initializeFirebase} from "../../util/firebase";
import ExplanationBox from "../../components/Dashboard/ExplanationBox";
import StatBox from "../../components/Dashboard/StatBox";
import UserWalletRewards from "../../components/Dashboard/UserWalletRewards";
import SellShareBox from "../../components/Dashboard/SellShareBox";
// Cookie
import CookieConsent from "react-cookie-consent";
import ReferalBox from "../../components/Dashboard/ReferalBox";
import NftBox from "../../components/Dashboard/NftBox";

initializeFirebase();


let provider;
let metamaskInstalled = false;
if (window.ethereum != null) {
    metamaskInstalled = true;
    console.log("Metamask installed.");
    window.ethereum.enable();
    provider = new ethers.providers.Web3Provider(window.ethereum, "any");
} else {
    console.log("Metamask not installed.");
    provider = new ethers.providers.getDefaultProvider("https://rpc.ftm.tools");
}

const easyBlockContract = new ethers.Contract(CONTRACT_ADDRESS, EASYBLOCK_ABI, provider);
const usdcContract = new ethers.Contract("0x04068DA6C83AFCFA0e13ba15A6696662335D5B75", PURCHASE_TOKEN_ABI, provider);
const nftContract = new ethers.Contract(NFT_ADDRESS, NFT_ABI, provider)
const rewardContract = new ethers.Contract(REWARD_ADDRESS, REWARD_ABI, provider);

let signer = null;
let easyBlockWithSigner = null;
let nftContractWithSigner = null;
let rewardContractWithSigner = null;

let depositTokenContract = null;
let depositTokenContractWithSigner = null;

export default function Dashboard() {
    let pastDistribution = new Date();
    let nextDistribution = new Date("09/05/2022");

    let Difference_In_Time = nextDistribution.getTime() - pastDistribution.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    // WEB3 START
    const connectWalletHandler = async () => {
        if (!metamaskInstalled) {
            alert("Please install Metamask to use EasyBlock.");
            return;
        }
        try {
            await window.ethereum.enable();
            let chainId = await provider.getNetwork();
            chainId = chainId['chainId'];

            if (chainId !== 250) {
                if (window.confirm("Please switch to Fantom Network to use EasyBlock.")) {
                    await changeNetworkToFTM();
                }
            } else {
                await connectAndGetUserData();
            }
        } catch (e) {
            console.log(e);
        }
    };

    // WEB3 END
    // Chakra Color Mode
    const {colorMode, toggleColorMode} = useColorMode();
    const textColor = useColorModeValue("gray.700", "white");

    // General stats
    const [totalInvestments, setTotalInvestments] = useState(0);
    const [totalRewardsPaid, setTotalRewardsPaid] = useState(0);
    const [totalShareCount, setTotalShareCount] = useState(60);
    const [strongPrice, setStrongPrice] = useState(0);
    const [nodesOwned, setNodesOwned] = useState(0);
    const [purchaseTokenContract, setPurchaseTokenContract] = useState("");
    const [sharePrice, setSharePrice] = useState(0);
    const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
    const [notClaimedRewards, setNotClaimedRewards] = useState(0);
    const [premiumCollected, setPremiumCollected] = useState(0);
    const [maxSharesToBeSold, setMaxSharesToBeSold] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    // Different wallets start
    const [wallet1Rewards, setWallet1Rewards] = useState(0);
    const [wallet1Strong, setWallet1Strong] = useState(0);

    // Different wallets end
    const [shareHolderCount, setShareHolderCount] = useState(0);
    const [newInvestments, setNewInvestments] = useState(0);

    // User stats
    const [userWallet, setUserWallet] = useState("");
    const [userShares, setUserShares] = useState(0);
    const [userPendingRewards, setUserPendingRewards] = useState(0);
    const [totalUserRewards, setTotalUserRewards] = useState(0);
    const [purchaseAllowance, setPurchaseAllowance] = useState(0);

    const [sharesToBeBought, setSharesToBeBought] = useState(100);

    const inputBg = useColorModeValue("white", "gray.800");

    const overlayRef = React.useRef();

    // UI CONTROLLERS
    const [generalDataLoading, setGeneralDataLoading] = useState(true);
    const [userDataLoading, setUserDataLoading] = useState(true);
    const [isClaiming, setIsClaiming] = useState(false);
    const [isBuying, setIsBuying] = useState(false);
    const [isMinting, setIsMinting] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [buyError, setBuyError] = useState(false);
    const [priceLoading, setPriceLoading] = useState(true);
    const [rewardDistributing, setRewardDistributing] = useState(false);

    const [showWalletDetails, setShowWalletDetails] = useState(false);

    const {toasts} = useToasterStore();
    const TOAST_LIMIT = 1;

    // Referral
    const [totalReferralRewardsDistributed, setTotalReferralRewardsDistributed] = useState(0);
    const [userReferralPurchaseCount, setUserReferralPurchaseCount] = useState(0);
    const [userReferralRewards, setUserReferralRewards] = useState(0);
    const [referer, setReferer] = useState("0x0000000000000000000000000000000000000000");
    // Auto-compounding
    const [isAutoCompound, setIsAutoCompound] = useState(false);
    const [isToggleAutoCompounding, setIsToggleAutoCompounding] = useState(false);
    // NFT
    const [userNftCount, setUserNftCount] = useState(0);
    const [claimableReward, setClaimableReward] = useState(0);
    const [maxSupply, setMaxSupply] = useState(5000);
    const [minted, setMinted] = useState(0);
    const [userNFTs, setUserNFTs] = useState([]);

    useEffect(() => {
        let fullUrl = window.location.href;
        let splitUrl = fullUrl.split('?');
        if (splitUrl.length > 1) {
            let params = splitUrl[1];
            if (params.indexOf("r=") != -1) {
                let referer = params.slice(2, 44);
                setReferer(referer);
            }
        }
    }, []);

    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
            .forEach((t) => toast.remove(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
    }, [toasts]);

    // Web3 methods
    async function changeNetworkToFTM() {
        try {
            if (!window.ethereum) throw new Error("No crypto wallet found");
            await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: `0x${Number(250).toString(16)}`,
                    chainName: "Fantom",
                    nativeCurrency: {
                        name: "Fantom",
                        symbol: "FTM",
                        decimals: 18
                    },
                    rpcUrls: ["https://rpc.ftm.tools/"],
                    blockExplorerUrls: ["https://ftmscan.com/"]
                }]
            });
        } catch (e) {
            alert(e.message);
        }
    }


    async function getNeededAmountData() {
        // Current wallet's balance for till next node
        fetch('https://openapi.debank.com/v1/user/total_balance?id=0xc73D10A7A1dBD3dea1AAA5a32Bf03D72DFCBFDBe').then(response => response.json()).then(data => {
            try {
                setCurrentWalletBalance(data['total_usd_value']);
            } catch (e) {
            }

        });
        setWallet1Strong(0 + (Date.now() - 1667729521000) / 1000 / 60 / 60 / 24 * 0.075 * 17);
        setPriceLoading(false);
    }

    async function connectAndGetUserData() {
        try {
            // Info about signer
            signer = provider.getSigner();
            let shouldProceed = false;
            try {
                await signer.getAddress();
                shouldProceed = true;
            } catch (e) {
                setUserDataLoading(false);
            }
            if (signer != null && shouldProceed) {
                let walletAddress = await signer.getAddress();
                setUserWallet(walletAddress);
                easyBlockWithSigner = easyBlockContract.connect(signer);
                nftContractWithSigner = nftContract.connect(signer);
                rewardContractWithSigner = rewardContract.connect(signer);

                let userShares = parseInt(await easyBlockContract.shareCount(walletAddress), 10);

                setUserShares(userShares / 100);
                setIsConnected(true);

                // Deposit token contracts
                depositTokenContractWithSigner = depositTokenContract.connect(signer);
                let allowance = parseInt(await depositTokenContract.allowance(walletAddress, CONTRACT_ADDRESS), 10);
                setPurchaseAllowance(allowance);
                setUserDataLoading(false);

                // Referral
                setUserReferralPurchaseCount(parseInt(await easyBlockContract.referSaleCount(walletAddress), 10));
                setUserReferralRewards(parseInt(await easyBlockContract.referFeeEarned(walletAddress), 10) / (10 ** 6));

                // Autocompound
                let isUserAutoCompound = await easyBlockContract.isAutoCompounding(walletAddress);
                setIsAutoCompound(isUserAutoCompound);

                // NFT
                let balance = parseInt(await nftContract.balanceOf(walletAddress), 10);
                setUserNftCount(balance);
                let userNfts = [];
                for (let i = 0; i < balance; i++) {
                    userNfts.push(parseInt(await nftContract.tokenOfOwnerByIndex(walletAddress, i), 10));
                }
                console.log("User NFTs");
                console.log(userNfts);
                setUserNFTs(userNfts);
                setClaimableReward(parseInt(await rewardContractWithSigner.getAllRewards(), 10));


            } else {
                setIsConnected(false);
                setUserDataLoading(false);
            }
        } catch (e) {
            setIsConnected(false);
            console.log("Get user data error: ");
            console.log(e);
            await connectAndGetUserData();
        }
    }

    async function getSmartContractData() {
        // Data from contract
        try {
            let totalInvestment = parseInt(await easyBlockContract.totalInvestment(), 10);
            let totalRewards = parseInt(await easyBlockContract.totalRewardsDistributed(), 10);
            let totalShares = parseInt(await easyBlockContract.totalShareCount(), 10);
            let purchaseTokenAddress = await easyBlockContract.purchaseToken();
            let sharePrice = parseInt(await easyBlockContract.getSharePrice(), 10);
            let totalNodesOwned = parseInt(await easyBlockContract.nodeCount(), 10);
            let investment = parseInt(await easyBlockContract.newInvestments(), 10);
            let sharePurchaseEnabled = await easyBlockContract.sharePurchaseEnabled();
            let holderCount = parseInt(await easyBlockContract.holderCount(), 10);
            let premiumCollected = parseInt(await easyBlockContract.premiumCollected(), 10);
            let maxSharesToSold = parseInt(await easyBlockContract.getMaxAmountOfSharesToBeSold(), 10);
            let sellPrice = parseInt(await easyBlockContract.getSellPrice(), 10);
            let totalReferralRewards = parseInt(await easyBlockContract.totalReferralRewardDistributed(), 10);

            setTotalInvestments(totalInvestment);
            setTotalRewardsPaid(totalRewards / 1000000); // USDC has 6 decimals
            setTotalShareCount(totalShares / 100); // Shares have 2 decimals
            setPurchaseTokenContract(purchaseTokenAddress);
            setSharePrice(sharePrice / 1000000); // USDC has 6 decimals
            setNodesOwned(35);
            setNewInvestments(investment / 1000000); // USDC has 6 decimals
            setPremiumCollected(premiumCollected / 1000000); // USDC has 6 decimals
            setRewardDistributing(!sharePurchaseEnabled);
            setShareHolderCount(holderCount);
            setMaxSharesToBeSold(Math.floor(maxSharesToSold / 100)); // Shares have 2 decimals
            setSellPrice(sellPrice / 1000000 * 100); // USDC has 6 decimals and shares have 2 decimals
            setTotalReferralRewardsDistributed(totalReferralRewards / 1000000); // USDC has 6 decimals


            // Deposit token contracts
            depositTokenContract = new ethers.Contract(purchaseTokenAddress, PURCHASE_TOKEN_ABI, provider);

            // NFT
            setMaxSupply(parseInt(await nftContract.maxSupply(), 10));
            setMinted(parseInt(await nftContract.numTokensMinted(), 10));

            // UI Change
            setGeneralDataLoading(false);
            console.log("Smart contract data here")

            await connectAndGetUserData()
        } catch (e) {
            console.log("General methods error: ");
            console.log(e);
            let chainId = await provider.getNetwork();
            chainId = chainId['chainId'];
            if (chainId !== 250) {
                if (window.confirm("Please switch to Fantom Network to use EasyBlock.")) {
                    await changeNetworkToFTM();
                }
            } else {
                getSmartContractData();
            }
        }
    }

    useEffect(async () => {
        if (colorMode === "light") {
            toggleColorMode();
        }
        try {
            // Strong price from coin gecko
            fetch('https://api.dexscreener.io/latest/dex/pairs/ethereum/0x453a43e2bf3080f7a23c9bb034ccdd869e306102').then(response => response.json()).then(data => {
                    let price = data.pair.priceUsd;
                    setStrongPrice(price);
                }
            );
        } catch (e) {
            console.log(e);
        }
        try {
            await getNeededAmountData();
        } catch (e) {
            console.log(e);
        }

        await getSmartContractData();
    }, [signer]);

    // CONTRACT INTERACTION FUNCTIONS
    async function claimRewards() {
        if (signer != null) {
            setIsClaiming(true);
            await easyBlockWithSigner.claimRewards();
        } else {
            await connectWalletHandler();
        }
    }

    async function buyShares(count) {
        let approvalAmount = 10000000000;
        if (count * 10 * 1000000 > approvalAmount) {
            approvalAmount = count * 10 * 1000000;
        }
        setBuyError(false);
        try {
            if (signer != null) {
                setIsBuying(true);
                if (purchaseAllowance >= count * 10 * 1000000) {
                    await easyBlockWithSigner.buyShares(count, referer);
                } else {
                    await depositTokenContractWithSigner.approve(CONTRACT_ADDRESS, approvalAmount);
                }
            } else {
                await connectWalletHandler();
            }
        } catch (e) {
            console.log(e);
            toast.error("Transaction error occured. Please be sure you have enough USDC in your account.", {duration: 5000,});
            setIsBuying(false);
            setBuyError(true);
        }
    }

    async function sellShares(count) {
        try {
            if (signer != null) {
                await easyBlockWithSigner.sellBackShares(count);
            }
        } catch (e) {
            console.log(e);
            toast.error("An error has occured please check the transaction, refresh, and try again.", {duration: 5000,});
        }
    }

    // NFT interactions
    async function mintNFT(count) {
        try {
            if (signer != null) {
                setIsMinting(true);
                const options = {value: ethers.utils.parseEther((20 * count).toString())}
                await nftContractWithSigner.mintForSelf(count, options);
            } else {
                await connectWalletHandler();
            }
        } catch (e) {
            console.log(e);
            toast.error("An error occurred. Can't mint NFT.", {duration: 5000,});
            setIsMinting(false);
        }
    }

    async function mintNFTFtm(count) {
        try {
            if (signer != null) {
                setIsMinting(true);
                const options = {value: ethers.utils.parseEther((100 * count).toString())}
                await nftContractWithSigner.mintForSelfFtm(count, options);
            } else {
                await connectWalletHandler();
            }
        } catch (e) {
            console.log(e);
            toast.error("An error occurred. Can't mint NFT.", {duration: 5000,});
            setIsMinting(false);
        }
    }

    async function claimRewards() {
        try {
            if (signer != null) {
                setIsClaiming(true);
                await rewardContractWithSigner.claimAll();
            } else {
                await connectWalletHandler();
            }
        } catch (e) {
            console.log(e);
            toast.error("An error occurred. Can't claim reward.", {duration: 5000,});
            setIsClaiming(false);
        }
    }

    // CONTRACT EVENT LISTENERS
    easyBlockContract.on("Investment", async (shareCount, price, address, event) => {
            if (event.event === "Investment" && address === await signer.getAddress()) {
                setGeneralDataLoading(true);
                setUserDataLoading(true);
                // await getSmartContractData();
                window.location.reload();
                setIsBuying(false);
                setSharesToBeBought(0);
                toast.success("Shares bought successfully. Your balance will be updated soon.", {duration: 5000,});
            }
        }
    );

    usdcContract.on("Approval", async (target, spender, value, event) => {
        if (event.event === "Approval" && signer != null && target === await signer.getAddress() && spender === CONTRACT_ADDRESS) {
            await updateAllowance();
            setIsBuying(false);
            toast.success("Approval successful. You can buy your shares now!", {duration: 5000,});
        }
    });

    nftContract.on("Transfer", async (from, to, tokenId, event) => {
        console.log("Inside event.");
        console.log(event);
        if (event.event === "Transfer" && to === await signer.getAddress()) {
            console.log("Should act.")
            await getSmartContractData();
            await connectAndGetUserData();
            setIsMinting(false);
            toast.success("NFT minted successfuly.", {duration: 5000,});
        }
    })

    rewardContract.on("Claim", async (address, amount, token, event) => {
        console.log("Inside event.");
        console.log(event);
        if (event.event === "Claim" && address === await signer.getAddress()) {
            console.log("Should act.")
            await getSmartContractData();
            await connectAndGetUserData();
            setIsClaiming(false);
            toast.success("Reward Claimed.", {duration: 5000,});
        }
    });

    provider.on("network", (newNetwork, oldNetwork) => {
        if (oldNetwork) {
            window.location.reload();
        }
    });

    // Allowance
    async function updateAllowance() {
        try {
            let allowance = await depositTokenContractWithSigner.allowance(await signer.getAddress(), CONTRACT_ADDRESS);
            setPurchaseAllowance(allowance);
        } catch (e) {
            await updateAllowance()
        }
    }

    // Reward calculations
    function calculateCurrentRewardSingle(reward) {
        reward *= strongPrice;
        reward += premiumCollected; // the premium from new share sales (dilution prevention amount + 5%)
        return (reward) / totalShareCount * (userShares + userNftCount * 50) * 0.9;
    }

    function calculateEstimatedRewardsSingle(reward) {
        reward *= strongPrice;
        reward = reward / (30 - Difference_In_Days) * 30;
        reward += premiumCollected; // the premium from new share sales (dilution prevention amount + 5%)
        return (reward) / totalShareCount * (userShares + userNftCount * 50) * 0.9;
    }

    return (
        <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 32}}>
            <Toaster/>
            <Portal>
                <AdminNavbar
                    signer={signer}
                    connectWalletHandler={() => connectWalletHandler()}
                    setSigner={(newSigner) => {
                        signer = newSigner;
                    }}
                    logoText={"EasyBlock Strong"}
                    isConnected={isConnected}
                />
            </Portal>
            <Flex flexDirection="column" pt={{base: "60px", md: "75px"}} maxWidth={"1400px"} paddingLeft={0}
                  paddingRight={0}>
                <ExplanationBox strongPrice={strongPrice} sharePrice={sharePrice}/>
                <StatBox strongPrice={strongPrice} generalDataLoading={generalDataLoading} wallet1Strong={wallet1Strong}
                         wallet1Rewards={wallet1Rewards}
                         nodesOwned={nodesOwned} totalInvestments={totalInvestments}
                         newInvestments={newInvestments} shareHolderCount={shareHolderCount}
                         totalShareCount={totalShareCount} priceLoading={priceLoading}
                         currentWalletBalance={currentWalletBalance}
                         totalRewardsPaid={totalRewardsPaid}/>
                {isConnected ?
                    <NftBox
                        shareCount={userShares}
                        mintNFT={async (count) => await mintNFT(count)}
                        mintNFTFtm={async (count) => await mintNFTFtm(count)}
                        isMinting={isMinting}
                        userNftCount={userNftCount}
                        claimableReward={claimableReward}
                        isClaiming={isClaiming}
                        claimRewards={async () => await claimRewards()}
                        NFT_ADDRESS={NFT_ADDRESS}
                        maxSupply={maxSupply}
                        minted={minted}
                        userNFTs={userNFTs}
                        nftContract={nftContract}/> : null}

                <Card minHeight="290.5px" p="1.2rem">
                    <CardBody w="100%">
                        <Flex flexDirection={{sm: "column", lg: "row"}} w="100%">
                            <Flex
                                flexDirection="column"
                                h="100%"
                                lineHeight="1.6"
                                width={{lg: "45%"}}
                            >
                                <Text fontSize="sm" color="gray.400" fontWeight="bold">
                                    Connected
                                    Wallet: {userDataLoading ? <Spinner/> : <Text>
                                    {
                                        !isConnected ? "Please Connect Wallet" : userWallet}</Text>}
                                </Text>
                                <Text
                                    fontSize="xl"
                                    color={textColor}
                                    fontWeight="bold"
                                    pb=".5rem"
                                    marginTop="8px"
                                >
                                    - Shares Owned: {userDataLoading ? <Spinner/> : <span>
                                        {userShares}</span>}
                                </Text>

                                {(userDataLoading || !isConnected) ? null :
                                    isAutoCompound ?
                                        <Button
                                            bg={"red.500"}
                                            p="0px"
                                            variant="no-hover"
                                            my={{sm: "0px", lg: "0px"}}
                                            onClick={async () => {
                                                setIsToggleAutoCompounding(true);
                                                await easyBlockWithSigner.setAutoCompounding(false);
                                                setTimeout(async () => {
                                                    await connectAndGetUserData();
                                                    setIsToggleAutoCompounding(false);
                                                }, 15000);
                                            }}
                                            paddingLeft={8}
                                            paddingRight={8}
                                            paddingTop={6}
                                            paddingBottom={6}
                                            style={{marginBottom: 8}}
                                        >
                                            {isToggleAutoCompounding ? <Spinner/> : <Text
                                                fontSize="16"
                                                color={"#ffffff"}
                                                fontWeight="bold"
                                                cursor="pointer"
                                                transition="all .5s ease"
                                                my={{sm: "1.5rem", lg: "0px"}}
                                            >
                                                {"Disable Auto-Compounding"}
                                            </Text>}
                                        </Button>
                                        :
                                        <Button
                                            bg={"#43a047"}
                                            p="0px"
                                            variant="no-hover"
                                            my={{sm: "0px", lg: "0px"}}
                                            onClick={async () => {
                                                setIsToggleAutoCompounding(true);
                                                await easyBlockWithSigner.setAutoCompounding(true);
                                                setTimeout(async () => {
                                                    await connectAndGetUserData();
                                                    setIsToggleAutoCompounding(false);
                                                }, 15000);
                                            }}
                                            paddingLeft={8}
                                            paddingRight={8}
                                            paddingTop={6}
                                            paddingBottom={6}
                                            style={{marginBottom: 8}}
                                        >
                                            {isToggleAutoCompounding ? <Spinner/> : <Text
                                                fontSize="16"
                                                color={"#ffffff"}
                                                fontWeight="bold"
                                                cursor="pointer"
                                                transition="all .5s ease"
                                                my={{sm: "1.5rem", lg: "0px"}}
                                            >
                                                {"Enable Auto-Compounding"}
                                            </Text>}
                                        </Button>}


                                <UserWalletRewards userDataLoading={userDataLoading}
                                                   totalShareCount={totalShareCount}
                                                   userShares={userShares + userNftCount * 50}
                                                   reward={wallet1Strong}
                                                   name={"Wallet 1"}
                                                   distributionDate={"December 5"}
                                                   strongPrice={strongPrice}
                                                   nextWallet={true}
                                                   premiumCollected={premiumCollected}/>

                                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                    (*) This is the reward accumulated from Strongblock but not yet claimed and
                                    distributed. Remember that the distributed amount can be lower because of
                                    Strongblock's claim and maintenance fees.
                                </Text>
                                <Spacer/>

                            </Flex>
                            <Spacer/>
                            <Flex
                                bg="#FFFFFF"
                                align="center"
                                justify="center"
                                borderRadius="15px"
                                flexDirection={"column"}
                                padding={4}
                                width={window.innerWidth < 960 ? "100%" : "50%"}
                            >
                                <Image
                                    src={'/coins/UsdcLogo.png'}
                                    alt="chakra image"
                                    width={100}
                                />
                                <Text style={{
                                    marginBottom: 16,
                                    fontSize: 16,
                                    color: "#3e68a4",
                                    marginTop: 8,
                                    textAlign: 'center',
                                }}><b>Next Reward Distribution:</b><br/>December 5<br/>
                                    <span
                                        style={{
                                            fontWeight: 'normal',
                                            fontSize: 14
                                        }}>{/*Because of the low price of $STRNGR the distribution couldn't happen at the moment because the gas fees to claim outweigh the rewards.*/}
                                        </span>
                                    <br/>
                                </Text>

                            </Flex>
                        </Flex>
                    </CardBody>
                </Card>
            </Flex>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                style={{background: "#2B373B"}}
                buttonStyle={{color: "#ffffff", backgroundColor: "#3e68a4", fontSize: "13px"}}
                expires={150}
            >
                By using this website you agree to our use of cookies, our{" "}
                <a href={"https://drive.google.com/file/d/1gabHyca4TJOvfrNWvFpbWRY02bZGlzdq/view"}
                   style={{textDecoration: "underline"}} target={"_blank"}>Terms of Use</a>, and {" "}
                <a href={"https://strongblock.com/terms-of-service.html"} style={{textDecoration: "underline"}}
                   target={"_blank"}>Strongblock's
                    Terms of Use</a>.
            </CookieConsent>
        </div>
    );
}
